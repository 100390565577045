import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="set-to-hidden"
export default class extends Controller {
  static targets = ["hidden"];

  set_value(event) {
    event.preventDefault();
    this.hiddenTarget.value = event.target.dataset.statusValue;
    this.element.submit();
  }
}
